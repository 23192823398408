'use client';

import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

export type Themes = 'light' | 'dark' | 'native';

interface ThemeContextType {
  disabled?: boolean; // Allows disabling of theme; Useful when components are using `useTheme` hook but site doesn't support theming
  theme: Themes;
  setTheme: (theme: Themes) => void;
}

export const LOCAL_STORAGE_KEY = 'theme';

function getInitialTheme(): Themes {
  if (typeof window !== 'undefined') {
    const persistedColorPerference = window.localStorage.getItem(LOCAL_STORAGE_KEY) as Themes;
    const hasPersistedPreference = typeof persistedColorPerference === 'string';

    // If the user has explicitly chosen 'light' or 'dark', use it. Otherwise, this value will be null.
    if (
      hasPersistedPreference &&
      (persistedColorPerference === 'light' ||
        persistedColorPerference === 'dark' ||
        persistedColorPerference === 'native')
    ) {
      return persistedColorPerference as Themes;
    }
  }

  // If haven't been chosen, check the media query.
  if (typeof window !== 'undefined' && window.matchMedia) {
    const mql = window.matchMedia('(prefers-color-scheme:dark)');
    const hasMediaQueryPreference = typeof mql.matches === 'boolean';

    if (hasMediaQueryPreference) {
      return mql.matches ? 'dark' : 'light';
    }
  }

  // If browser/OS doesn't support color themes, default theme to 'light'.
  return 'light';
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

interface Props {
  children: ReactNode;
  disabled?: boolean;
}

const ThemeProvider = ({ children, disabled = false }: Props) => {
  const [theme, rawSetTheme] = useState<Themes>(disabled ? 'light' : getInitialTheme);

  useEffect(() => {
    if (disabled) {
      return;
    }
    const applyTheme = (newTheme: Themes) => {
      let appliedTheme = newTheme;
      if (typeof window !== 'undefined' && newTheme === 'native') {
        appliedTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
      }
      document.documentElement.setAttribute('data-mode', appliedTheme);
    };

    const storedTheme = localStorage.getItem(LOCAL_STORAGE_KEY) as Themes;
    if (storedTheme) {
      applyTheme(storedTheme);
    } else {
      applyTheme(theme);
    }
  }, [disabled, theme]);

  const setTheme = (value: Themes) => {
    if (disabled) {
      console.warn('Cannot set theme as ThemeProvider has been disabled');
      return;
    }

    rawSetTheme(value);

    // Persist it on update
    localStorage.setItem(LOCAL_STORAGE_KEY, value);

    let newTheme = value;
    // If given theme is native, then check matches
    if (value === 'native') {
      newTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    document.documentElement.setAttribute('data-mode', newTheme);
  };

  const value: ThemeContextType = {
    theme,
    setTheme,
  };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
