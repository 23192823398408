import { merge } from 'lodash';

import { camelize } from './utils.ts';

export interface SuccessResponse<T> {
  success: true;
  data: T;
  error?: never;
}

export interface ErrorResponse {
  success: false;
  data?: never;
  error: {
    code: string;
    message: string;
  };
}

export type ApiResponse<T> = SuccessResponse<T> | ErrorResponse;

interface LoginRequest {
  email: string;
  password: string;
}

export default class ListingApi {
  static async getSession(options: Omit<RequestInit, 'method'> = {}) {
    return await ListingApi.#get<ListingApi.Session>('/api/session', options);
  }

  static async login(req: LoginRequest, options: Omit<RequestInit, 'method'> = {}) {
    return await ListingApi.#post<ListingApi.Session>('/api/login', req, options);
  }

  static async resetPassword(email: string, url: string, options: Omit<RequestInit, 'method'> = {}) {
    return await ListingApi.#post<ListingApi.Session>('/api/reset-password', { email, url }, options);
  }

  static async setPassword(
    code: string,
    password: string,
    passwordConfirmation: string,
    options: Omit<RequestInit, 'method'> = {},
  ) {
    return await ListingApi.#post(
      `/api/set-password/${code}`,
      {
        password,
        password_confirmation: passwordConfirmation,
      },
      options,
    );
  }

  static async activate(
    code: string,
    password: string,
    passwordConfirmation: string,
    options: Omit<RequestInit, 'method' | 'body'> = {},
  ) {
    return await ListingApi.#post(
      `/api/activate/${code}`,
      {
        password,
        password_confirmation: passwordConfirmation,
      },
      options,
    );
  }

  static async logout(options: Omit<RequestInit, 'method'> = {}) {
    return await ListingApi.#delete<ListingApi.Session>('/api/logout', options);
  }

  static async #get<T>(path: string, options?: Omit<RequestInit, 'method'>) {
    const response = await fetch(
      `${ListingApi.#host()}${path}`,
      merge(
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          cache: 'no-store',
        },
        options,
      ),
    );

    switch (response.status) {
      case 500:
        throw new ListingApiError(response);
      default:
        const data = await response.json();
        return camelize(data) as ApiResponse<T>;
    }
  }

  static async #post<T>(path: string, body: Record<string, any>, options?: Omit<RequestInit, 'method' | 'body'>) {
    const response = await fetch(
      `${ListingApi.#host()}${path}`,
      merge(
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          cache: 'no-store',
          body: JSON.stringify(body),
        },
        options,
      ),
    );

    switch (response.status) {
      case 500:
        throw new ListingApiError(response);
      default:
        const data = await response.json();
        return camelize(data) as ApiResponse<T>;
    }
  }

  static async #delete<T>(path: string, options?: Omit<RequestInit, 'method'>) {
    const response = await fetch(
      `${ListingApi.#host()}${path}`,
      merge(
        {
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          cache: 'no-store',
        },
        options,
      ),
    );

    switch (response.status) {
      case 500:
        throw new ListingApiError(response);
      default:
        return camelize(await response.json()) as ApiResponse<T>;
    }
  }

  static #host() {
    if (process.env.NODE_ENV === 'test') {
      return 'http://test.localhost';
    }
    if (typeof window === 'undefined') {
      return `http://${process.env.NGINX_HOST || 'localhost'}`;
    }
    return `${window.location.origin}`;
  }
}

export class ListingApiError extends Error {
  constructor(response: Response, body?: Record<string, any>) {
    super();
    this.name = `ListingApiError: ${response.status}`;
    if (body?.error) {
      this.message = body?.error?.message;
    } else {
      this.message = `Request failed with status code ${response.status}`;
    }
  }
}
