'use client';

import React from 'react';

import SessionProvider from '@/common/lib/Session/provider.tsx';
import ThemeProvider from '@/common/lib/ThemeProvider.tsx';

export function Providers({ children }: React.PropsWithChildren) {
  return (
    <ThemeProvider disabled>
      <SessionProvider>{children}</SessionProvider>
    </ThemeProvider>
  );
}
