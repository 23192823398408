'use client';

import React, { createContext } from 'react';
import useSWR from 'swr';

import ListingApi from '@/common/lib/ListingApi.ts';

interface SessionContextType {
  session?: ListingApi.Session;
  loading: boolean;
}

export const SessionContext = createContext<SessionContextType | null>(null);
export default function SessionProvider({ children }: React.PropsWithChildren) {
  const { data, isLoading } = useSWR('/api/session', async () => await ListingApi.getSession());
  return (
    <SessionContext.Provider value={{ session: data?.data, loading: isLoading }}>
      <div data-testid={isLoading ? 'session_loading' : 'session_loaded'}>{children}</div>
    </SessionContext.Provider>
  );
}
